(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-competition/assets/competition.js') >= 0) return;  svs.modules.push('/components/payment/middleware-competition/assets/competition.js');


'use strict';

const {
  logger,
  constants
} = svs.components.payment.common;
const {
  analytics
} = svs.components;
const {
  services
} = svs.components.marketplaceCompetition.services;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  resolveCompetitionName
} = svs.components.marketplaceCompetition.helpers;
const {
  SignupError
} = svs.components.payment.common.error;
const {
  SignupType,
  ScopeType
} = svs.components.marketplaceCompetition.constants;
const {
  isWager
} = svs.components.payment.createWager.utils;
const getValidCompetition = (compHandler, wager) => {
  for (let i = 0; i < wager.bets.length; i++) {
    const bet = wager.bets[i];
    const board = bet.boards[0];
    const competition = compHandler.getActiveCompetition(bet.productId);
    if (competition && bet.systemSize === competition.getSystemSize() && board.systemType === competition.getSystemType()) {
      return {
        competition,
        bet
      };
    }
  }
  return {};
};

const getPlayerCompetitionDetails = async compHandler => {
  const {
    playerCompetitionDraws,
    playerCompetitions,
    status
  } = compHandler.getPlayerCompetitionDetails();

  if (!status) {
    logger.info('Details not fetched serverside so fetching client side');
    const result = await compHandler.fetchPlayerCompetitionDetails(undefined, true, true);
    logger.debug('>>', result);
    return [result[1] || {}, result[0] || {}];
  }
  return [playerCompetitionDraws, playerCompetitions];
};

const findPlayableCompetition = async payableItems => {
  const compHandler = competitionHandler();
  if (!compHandler.getActiveCompetitions().length) {
    logger.info('No active competitions found');
    return {};
  }
  let playerCompetitionDraws;
  let playerCompetitions;
  for (let i = 0; i < payableItems.length; i++) {
    const wager = payableItems[i];
    if (!isWager(wager)) {
      continue;
    }
    const productBet = wager.getProductBet;
    if (!productBet || productBet.isOwnRows || productBet.numberOfDraws > 1) {
      continue;
    }

    const {
      competition,
      bet
    } = getValidCompetition(compHandler, wager);

    if (competition !== null && competition !== void 0 && competition.isFinished()) {
      break;
    }
    if (competition) {
      var _playerCompetitionDra;
      const currentDrawNumber = competition.getCurrentDrawNumber();
      const competitionId = competition === null || competition === void 0 ? void 0 : competition.getCompetitionId();

      [playerCompetitionDraws, playerCompetitions] = await getPlayerCompetitionDetails(compHandler);

      if ((_playerCompetitionDra = playerCompetitionDraws[competitionId]) !== null && _playerCompetitionDra !== void 0 && _playerCompetitionDra.find(drawNumber => drawNumber === currentDrawNumber)) {
        break;
      }
      const isParticipating = Boolean(playerCompetitions[competitionId]);
      const isFirstDraw = currentDrawNumber === competition.getFirstDrawNumber();
      const isPlayable = competition.isPlayable(isParticipating);
      if (
      isParticipating && isPlayable ||
      isFirstDraw && isPlayable) {
        return {
          bet,
          competition,
          isFirstDraw,
          isParticipating,
          wager
        };
      }
    }
  }
  return {};
};
const competition = onInteraction => _ref => {
  let {
    paymentInfo,
    payment
  } = _ref;
  return async next => {
    logger.info('Running competition middleware');
    const response = await findPlayableCompetition(payment.getPayableItems());
    if (response.competition) {
      try {
        logger.info('Wager matches competition, showing competition dialog.');
        const {
          bet,
          competition,
          wager
        } = await onInteraction(response);
        logger.info('User accepted to participate in competition with bet');
        const competitionId = competition.getCompetitionId();
        const compName = resolveCompetitionName(competition.getData());

        paymentInfo.add({
          message: "Ditt spel kommer att delta i ".concat(compName, "."),
          localId: wager.localId,
          type: constants.paymentInfoType.PRE
        });
        paymentInfo.add({
          localId: wager.localId,
          type: constants.paymentInfoType.POST,
          extraData: {
            isCompetitionSignUp: !response.isParticipating,
            compName
          }
        });
        payment.tasks.add(wager.localId, () => new Promise((resolve, reject) => {
          if (!response.isParticipating) {
            services.putParticipation(competitionId, {
              signupType: SignupType.PARTICIPATE,
              scopeType: ScopeType.INDIVIDUAL_ONLY,
              locationId: 0
            }).then(() => {
              logger.info("Anm\xE4lan till ".concat(compName, " \xE4r genomf\xF6rd"), response);
              const isCompetitionSignUp = true;
              wager.addCompetitionForBet(competitionId, bet, isCompetitionSignUp);
              analytics.trackEvent({
                category: competition.getCompType(),
                action: 'joined team',
                opt_label: 'Individuellt'
              });
              return resolve();
            }).catch(err => {
              const {
                error
              } = err.responseJSON;
              logger.error("Anm\xE4lan till ".concat(compName, " gick inte att genomf\xF6ra"), error);
              reject(new SignupError({
                clientCode: SignupError.ClientErrorCodes.COMPETITION_ERROR,
                code: error.code,
                extraData: {
                  message: "SignupError - code: ".concat(error.code, ", message: ").concat(error.message)
                },
                message: "Anm\xE4lan till ".concat(compName, " gick inte att genomf\xF6ra")
              }));
            });
          } else {
            const isCompetitionSignUp = false;
            wager.addCompetitionForBet(competitionId, bet, isCompetitionSignUp);
            resolve();
          }
        }));
      } catch (err) {
        logger.info('User declined to participate in competition');
      }
    }
    next();
  };
};
setGlobal('svs.components.payment.middleware.competition', competition);

 })(window);